













































import './scss/TheAuth.scss';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { userNamespace } from '@store/modules/user';
import { UserActionTypes } from '@store/modules/user/Types';
import { isEmail } from '@utility/rules.js';
import { makeFocusInput } from '@utility/makeFocusInput';

@Component({
    name: 'ForgotPassword',

    components: {
        BaseAuth: () => import('@pages/the-auth/TheBaseAuth.vue'),
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
        PopupConfirmation: () => import('@components/popups/PopupConfirmation.vue'),
    },
})
export default class ForgotPassword extends Vue {
    $refs!: {
        mainForm: HTMLElement,
    }

    @userNamespace.Action(UserActionTypes.A_FORGOT_PASSWORD) forgotPasswordMethod!: (payload) => Promise<void>;

    fieldLogin: string = '';
    error: string = '';
    realTimeValidation: boolean = false;
    errorsText: {[key: string]: string} = {
        empty: 'Не заполнено обязательное поле',
        emailFormat: 'Неверный формат E-mail',
    }
    informationPopupShow: boolean = false;
    durationShowingNotificationPopup: number = 5000;
    informationPopupText: string = '';

    mounted() {
        setTimeout(() => {
            makeFocusInput(this.$refs.mainForm);
        }, 100);
    }

    validateForm() {
        this.realTimeValidation = true;
        const email = this.fieldLogin;
        if (!email) {
            this.error = this.errorsText.empty;
        } else {
            this.error = '';
        }
        if (!this.error && email) {
            this.error = isEmail(email) ? '' : this.errorsText.emailFormat;
        }
    }

    clickSubmit() {
        this.validateForm();
        if (!this.error) {
            this.dispatchForgotPassword();
        }
    }

    dispatchForgotPassword() {
        this.forgotPasswordMethod({ email: this.fieldLogin })
            .then(() => {
                this.informationPopupText = 'Сообщение с ссылкой для сброса пароля отправлено на указанный вами E-mail';
            })
            .catch(() => {
                this.informationPopupText = 'Во время отправки запроса произошла ошибка. Возможно вы указали некорректный E-mail.';
            })
            .finally(() => {
                this.realTimeValidation = false;

                this.informationPopupShow = true;
                setTimeout(() => {
                    this.informationPopupShow = false;
                }, this.durationShowingNotificationPopup);
            });
    }

    checkValidation() {
        if (this.realTimeValidation) {
            this.validateForm();
        }
    }
}
